<template>
  <form @submit.prevent="submitMensaje" class="content-Mensaje">
    <div class="text-end text-light">
      <Cerrar />
    </div>

    <div class="row">
      <h4 v-if="objMensaje.id" class="text-light text-center">
        Editando Registro
      </h4>
      <h4 v-else class="text-light text-center">Nuevo Registro</h4>
    </div>

    <div class="row">
      <div class="col-md-2">
        <label for="" class="text-light">Tipo</label>
        <select
          name=""
          id=""
          class="form-select"
          v-model="objMensaje.tipo_mensaje_id"
          :disabled="objMensaje.id"
        >
          <option v-for="t of lstMensajesTipo" v-bind:key="t.id" :value="t.id">
            {{ t.nombre }}
          </option>
        </select>

        <small class="form-text txt-red" v-if="v$.tipo_mensaje_id.$error">
          *Debe agregar un tipo.
        </small>
      </div>
      <div class="col-md-2">
        <div class="form-check form-switch text-center mt-4">
          <input
            v-model="objMensaje.status"
            class="form-check-input"
            type="checkbox"
            :checked="objMensaje.status"
          />
          <label v-if="objMensaje.status" class="form-check-label text-light"
            >Activo</label
          >
          <label v-else class="form-check-label text-light">Inactivo</label>
        </div>
      </div>

      <div class="col-md-2">
        <label class="form-check-label text-light"
          >Correo por enviar: {{ objMensaje.correo_por_enviar }}</label
        ><br />
        <label class="form-check-label text-light"
          >Correo enviado: {{ objMensaje.correo_enviado }}</label
        >
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-3">
        <label class="text-light">Titulo</label>
        <input
          type="text"
          v-model="objMensaje.titulo"
          :class="{
            'form-control': true,
          }"
        />
        <small class="form-text txt-red" v-if="v$.titulo.$error">
          *Debe agregar un titulo.
        </small>
      </div>
      <div class="col-md-3">
        <label for="" class="text-light">Fecha de envio</label>
        <input type="date" class="form-control" v-model="objMensaje.fecha" />
        <small class="form-text txt-red" v-if="v$.fecha.$error">
          *Debe agregar una fecha.
        </small>
      </div>
      <div class="col-md-2" v-if="objMensaje.tipo_mensaje_id == 2">
        <label for="" class="text-light">Ultimo(s)</label>
        <Multiselect
          v-model="objMensaje.mes"
          :close-on-select="false"
          :searchable="true"
          :create-option="true"
          :options="[
            { value: '1', label: '1 mes' },
            { value: '2', label: '2 meses' },
            { value: '3', label: '3 meses' },
            { value: '4', label: '4 meses' },
            { value: '5', label: '5 meses' },
            { value: '6', label: '6 meses' },
            { value: '7', label: '7 meses' },
            { value: '8', label: '8 meses' },
            { value: '9', label: '9 meses' },
            { value: '10', label: '10 meses' },
            { value: '11', label: '11 meses' },
            { value: '12', label: '12 meses' },
          ]"
        />
      </div>
      <div class="col-md-4">
        <label for="" class="text-light">Moneda</label>
        <div>
          <Multiselect
            v-model="objMensaje.moneda"
            mode="tags"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'USD', label: 'USD' },
              { value: 'VES', label: 'VES' },
              { value: 'COP', label: 'COP' },
            ]"
          />
        </div>
        <small class="form-text txt-red" v-if="v$.moneda.$error">
          *Debe agregar una moneda.
        </small>
      </div>
    </div>
    <div class="mt-2">
      <GaleriaBtn />
    </div>
    <div class="row">
      <div class="col-md-12 py-3">
        <CKEditor @sendContent="getContent" :content="objMensaje.contenido" />
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, ref } from "vue-demi";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CKEditor from "../../../components/CKEditor.vue";
import Cerrar from "../../../components/button/Cerrar.vue";
import Multiselect from "@vueform/multiselect";
import useMensaje from "../composable/useMensaje";
import Swal from "sweetalert2";
import GaleriaBtn from "../../../components/button/GaleriaBtn.vue";

export default {
  props: ["paginas"],
  components: {
    CKEditor,
    Cerrar,
    Multiselect,
    GaleriaBtn,
  },
  data() {
    return {
      value: [],
    };
  },
  setup() {
    const store = useStore();
    const editor = ref();
    const objMensaje = inject("objMensaje");
    const btn = inject("btn");
    const mensaje_tipo_id = inject("mensaje_tipo_id");
    const { createMensaje, editMensaje, getMensaje, lstMensajesTipo } =
      useMensaje();
    const getContent = (val) => {
      objMensaje.contenido = val;
    };

    const rules = {
      titulo: {
        required,
      },
      moneda: {
        required,
      },
      tipo_mensaje_id: {
        required,
      },
      fecha: {
        required,
      },
    };

    const v$ = useVuelidate(rules, objMensaje);

    const submitMensaje = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return false;
      }
      let resp = "";
      store.commit("showLoader");
      if (objMensaje.id) {
        resp = await editMensaje(objMensaje);
      } else {
        resp = await createMensaje(objMensaje);
      }
      await getMensaje({
        perPage: 0,
        tipo_mensaje_id: mensaje_tipo_id.value,
      });

      store.commit("hideLoader");
      mensaje_tipo_id.value = objMensaje.tipo_mensaje_id;

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
      cerrar();
    };

    const cerrar = () => (btn.value = false);

    onBeforeUnmount(() => {
      objMensaje.id = null;
      objMensaje.pagina_id = null;
      objMensaje.contenido = "";
      objMensaje.status = null;
      objMensaje.deleted = null;
      btn.value = false;
    });

    // const mensajesTipo = computed(() => store.state.mensajesTipo);

    return {
      editor,
      objMensaje,
      submitMensaje,
      cerrar,
      getContent,
      v$,
      lstMensajesTipo,
    };
  },
};
</script>

<style scoped="scoped">
.content-Mensaje {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
}
.img-ayuda {
  height: 150px !important;
  width: 200px;
}
</style>