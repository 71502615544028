<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Mensaje</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <MensajeTables  />
      </div>
      <div v-if="btn" class="col-12"><MensajeForm /></div>
    </div>
  </div>
</template>

<script>
import { reactive, provide, inject, ref } from "vue-demi";
import MensajeForm from "../components/MensajeForm.vue"
import MensajeTables from "../components/MensajeTables.vue"
// import { useStore } from "vuex";

export default {
  components: {
    MensajeForm,
    MensajeTables,
  },
  setup() {
    // const store = useStore();
    const btn = inject("btn");  
    const mensaje_tipo_id = ref(1);
    const objMensaje = reactive({
      id: null,
      titulo: "",
      contenido: "",
      fecha: null,
      status: true,
      deleted: false,
      moneda: [],
      tipo_mensaje_id: 1,
      mes: null,
      correo_por_enviar:0,
      correo_enviado:0,
      cron_ejecutado:false
    });  

    provide("objMensaje", objMensaje);
    provide("btn", btn);
    provide("mensaje_tipo_id", mensaje_tipo_id); 

    return { btn, objMensaje,mensaje_tipo_id};
  },
};
</script>

<style>
</style>