import { computed } from "vue";
import { useStore } from "vuex"; 

const useMensaje = () => {
  const store = useStore();

  const createMensaje = async (obj) => {
    console.log("Aqui estoy")
    const resp = await store.dispatch("mensaje/createMensaje", obj);
    return resp;
  };
  const editMensaje = async (obj) => {
    const resp = await store.dispatch("mensaje/editMensaje", obj);
    return resp;
  };
  const deleteMensaje = async (obj) => {
    const resp = await store.dispatch("mensaje/deleteMensaje", obj);
    return resp;
  };
  const getMensaje = async (obj) => {
    const resp = await store.dispatch("mensaje/getMensaje", obj);
    return resp;
  };
  const getMensajeTipo = async () => {
    const resp = await store.dispatch("mensaje/getMensajeTipo");
    return resp;
  };

  return {
    createMensaje,
    editMensaje,
    deleteMensaje,
    getMensaje,
    getMensajeTipo,
    lstMensajes: computed(() => store.getters["mensaje/getMensajes"]),
    lstMensajesTipo: computed(() => store.getters["mensaje/getMensajesTipo"]),    
  };
};

export default useMensaje;
