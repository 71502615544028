<template>
  <label class="text-light">Tipo Mensaje</label>
  <div class="row">
    <div class="col-md-3">
      <select
        class="form-select"
        @change="filtrarData"
        v-model="mensaje_tipo_id"
      >
        <option v-for="t of lstMensajesTipo" v-bind:key="t.id" :value="t.id">
          {{ t.nombre }}
        </option>
      </select>
    </div>
  </div>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="addMensaje">Agregar</button>
  </div>
  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Titulo</th>
          <!-- <th scope="col">Hora inicio</th> -->
          <th scope="col">Fecha</th>
          <th scope="col">Ultimo(s)</th>
          <th scope="col">Correo por enviar</th>
          <th scope="col">Correo enviado</th>
          <th scope="col">CRON</th>
          <th scope="col">Estatus</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="s of lstMensajes" v-bind:key="s.id">
        <tr style="cursor: pointer">
          <td @click="select(s)">{{ s.titulo }}</td>
          <!-- <td @click="select(s)">{{ timeFormat(s.fecha) }}</td> -->
          <td @click="select(s)">
            {{ dateFormat(s.fecha) }}
          </td>
          <td @click="select(s)">{{ s.mes }} meses</td>
          <td @click="select(s)">{{ s.correo_por_enviar }}</td>
          <td @click="select(s)">{{ s.correo_enviado }}</td>
          <td @click="select(s)">{{ validarCRON(s.cron_ejecutado) }}</td>
          <td @click="select(s)">{{ validarStatus(s.status) }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarMensaje(s)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, onMounted } from "vue-demi";
import useUtil from "../../../use/useUtil";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import useMensaje from "../composable/useMensaje";

export default {
  components: {},
  setup() {
    const { dateFormat, timeFormat, dateFormatInput } = useUtil();
    const objMensaje = inject("objMensaje");
    const btn = inject("btn");
    const store = useStore();
    const mensaje_tipo_id = inject("mensaje_tipo_id");
    const {
      getMensaje,
      getMensajeTipo,
      lstMensajes,
      lstMensajesTipo,
      deleteMensaje,
    } = useMensaje();
    const addMensaje = () => {
      objMensaje.id = null;
      objMensaje.titulo = "";
      objMensaje.contenido = "";
      objMensaje.fecha = null;
      objMensaje.status = true;
      objMensaje.deleted = false;
      objMensaje.moneda = [];
      objMensaje.tipo_mensaje_id = 1;
      objMensaje.mes = null;
      objMensaje.correo_por_enviar = 0;
      objMensaje.correo_enviado = 0;
      objMensaje.cron_ejecutado = false;
      btn.value = true;
    };

    const select = (e) => {
      objMensaje.id = e.id;
      objMensaje.titulo = e.titulo;
      objMensaje.contenido = e.contenido;
      objMensaje.status = e.status;
      objMensaje.moneda = e.moneda.split(",");
      objMensaje.tipo_mensaje_id = e.tipo_mensaje_id;
      objMensaje.mes = e.mes;
      objMensaje.correo_por_enviar = e.correo_por_enviar
        ? e.correo_por_enviar
        : 0;
      objMensaje.correo_enviado = e.correo_enviado ? e.correo_enviado : 0;
      objMensaje.cron_ejecutado = e.cron_ejecutado;
      objMensaje.fecha = dateFormatInput(e.fecha)
        ? dateFormatInput(e.fecha)
        : null;
      btn.value = true;
    };

    const validarStatus = (status) => (status ? "Mostar" : "Ocultar");
    const validarCRON = (status) => (status ? "Ejecutado" : "Sin ejecutar");

    const eliminarMensaje = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objMensaje.id = e.id;
          store.commit("showLoader");
          await deleteMensaje(objMensaje);
          await getMensaje({
            perPage: 0,
            tipo_mensaje_id: mensaje_tipo_id.value,
          });
          store.commit("hideLoader");
          return true;
        }
      });
    };

    onMounted(async () => {
      store.commit("showLoader");
      await getMensajeTipo();
      await filtrarData();
      store.commit("hideLoader");
    });

    const filtrarData = async () => {
      let obj = {
        perPage: 0,
        tipo_mensaje_id: mensaje_tipo_id.value,
      };
      await getMensaje(obj);
    };

    return {
      dateFormat,
      timeFormat,
      addMensaje,
      select,
      validarStatus,
      eliminarMensaje,
      // mensajesTipo,
      filtrarData,
      // mensajes,
      mensaje_tipo_id,
      validarCRON,
      lstMensajes,
      lstMensajesTipo,
    };
  },
};
</script>

<style>
</style>